.home-container{
    display: flex;
    flex-direction: column;
    block-size: 100%;
    overflow: hidden;
}
.page-background {
    position: relative;
    height: 100vh; /* Hauteur de la fenêtre */
  }
