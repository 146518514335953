:root{
  --blue: #94BCE9;
  --alternative-blue: #4273ac;
  --pink: #EEAECA ;
  --alternative-pink: #E080AA ;
  --text-color : #fff;
  --alternative-text-color: #000 ;
}
body {
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Raleway';
  display: flex;
  flex-direction: column;
  background-color: #000;
  block-size: 100%;
}
p{
  font-family: 'Merriweather';
}

a:visited{
 color: var(--alternative-text-color);
}
a:hover{
color: var(--alternative-blue);
}
