/*Main Container Settings*/
.introduction-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 8em 0 0 0;
}
.introduction-container a{
    color: black;
}
/*______________________Secondary Container______________________*/
/*______Social Container_______*/
:root {
    --size: 50vmin;
    --white: #fff;
    --blue: #3051f1;
    --purple: #c92bb7;
    --red: #f73344;
    --orange: #fa8e37;
    --yellow: #fcdf8f;
    --yellow_to: #fbd377;
  }

.introSocials-container{
position: absolute;
top: 10em;
align-self: center;
}
.introSocials-container ul{
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
}
.introSocials-container ul li a{
    width: 60px;
    height: 60px;
    background-color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 30px;
    margin: 0 50px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    z-index: 1;
    box-shadow: 1px 1px 6px rgb(0, 0, 0);
}
.introSocials-container ul li a:hover{
    box-shadow: 1px 1px 10px rgb(238, 223, 255);
}
.introSocials-container ul li a .icon{
position: relative;
  color: #262626;
  transition: .5s;
  z-index: 3;
}
.introSocials-container ul li a:hover .icon{
    color: #fff;
    transform: rotateY(360deg); 
}
.introSocials-container ul li a:before{
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 169, 207);
  transition: .5s;
  z-index: 2;
}
.introSocials-container ul li a:hover:before{
    top: 0;
}
.introSocials-container ul li:nth-child(1) a:before{
    background: #3b5999;
}
.introSocials-container ul li:nth-child(2) a:before{
    background: #000000;
}
.introSocials-container ul li:nth-child(3) a:before{
    background: #0077b5;
}
.introSocials-container ul li:nth-child(4) a:before{
    background: radial-gradient(
    circle farthest-corner at 28% 100%,
    var(--yellow) 0%,
    var(--yellow_to) 10%,
    var(--orange) 22%,
    var(--red) 35%,
    transparent 65%
  ), linear-gradient(145deg, var(--blue) 10%, var(--purple) 70%);
}
/*_____Introduction______*/
/*TEXT*/
.introduction-part{
    display: flex;
    flex-direction: row;
    margin: 10em 0 0 4em;
}
.introduction-part .text-container{
    margin: 0 0 0 5em;
}
.introduction-part .text-container h1{
    font-family: 'Merriweather';
    text-transform: uppercase;
    font-weight: 300;
    font-size: 45px;
    position: relative;
    background: linear-gradient(
        to right,
        rgb(255, 255, 255) 20%,
        #0fb7af 30%,
        #628695 70%,
        #da4a86 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
    margin: 0;
}
@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
  }
.introduction-part .text-container h2{
    font-family: 'Raleway';
    font-weight: 500;
    margin: 0.5em 0 0 0;
    text-transform: uppercase;  
}
.introduction-part .text-container p{
    width: 70%;
    font-family: 'Raleway';
    font-weight: 300;
    margin: 3em 0 0 0;
}
/*IMG*/
.introduction-part .pp-container{
    height: 200px;
    overflow: hidden;
    border-radius: 100px;
    border: var(--yellow) solid 2px;
    box-shadow: 1px 1px 10px rgb(238, 223, 255);
    margin: 0 0 0 5em;
    display: flex;
    min-width: 200px;
}
.introduction-part .pp-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/*CV Download*/
.cv-download{
    position: absolute;
    top: 32em;
    right: 77.5em;
    width: 80px;
}
.cv-download p{
    text-align: center;
    font-family: 'Raleway';
    color: white;
}
.dl-button{
    border-radius: 25px;
    box-shadow: 0px 0px 6px rgb(255, 255, 255);
    transform: scale(1);
    transition: ease 300ms;
    padding: 1em 0 1em 0;
    background-color: white;
}
.dl-button:hover{
    transform: scale(1.1);
    
}
  .downloading {
    width: 30px;
    height: 32px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .custom-arrow {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -7px;
    background-color: #4273ac;
    animation-name: downloading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  .custom-arrow:after {
    content: "";
    position: absolute;
    display: block;
    top: 100%;
    left: -9px;
    border-top: 15px solid #4273ac;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
  }
  
  .bar {
    width: 30px;
    height: 4px;
    background-color: #4273ac;
    margin: 0 auto;
  }
  
  @-webkit-keyframes downloading {
    0% {
      top: 0;
      opacity: 1;
    }
    50% {
      top: 110%;
      opacity: 0;
    }
    52% {
      top: -110%;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  @keyframes downloading {
    0% {
      top: 0;
      opacity: 1;
    }
    50% {
      top: 110%;
      opacity: 0;
    }
    52% {
      top: -110%;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
/*____________RESPONSIVE_____________*/
/*____MOBILE_____*/
@media (max-width: 768px) {
/*SOCIALS*/
.introSocials-container ul{
    align-self: center;
    left: -15px;
    top: 0;
}
.introSocials-container ul li a{
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin: 15px;
    line-height: 45px;
}
/*___TEXT PART & PICTURE ____*/
.introduction-part {
    flex-direction: column;
    margin: 10em 0 0 0;
}
.introduction-part .pp-container{
    height: 150px; 
    width: 100px;
    align-self: center;
    margin: 0;
}
.introduction-part .text-container{
    margin: 0;
}
.introduction-part .text-container h1{
    text-align: center;
    margin: 1em 0 0 0;
    font-size: 30px;
}
.introduction-part .text-container h2{
    text-align: center;
}
.introduction-part .text-container p{
    text-align: center;
    margin: 2em 0 0 10%;
    width: 80%;
}
.introduction-part .cv-download{
  top: 62em;
  left: 39%;
}
}