:root{
    --blue: #94BCE9;
    --alternative-blue: #4273ac;
    --pink: #EEAECA ;
    --alternative-pink: #E080AA ;
    --text-color : #fff;
    --alternative-text-color: #000;
    --neutral-background-color: #fff;
  }
.portfolio-container h1{
  display: flex;
  font-family: 'Merriweather';
  text-transform: uppercase; 
  font-weight: 200;
  letter-spacing: 10px;
  justify-content: center;
  margin: 0 0 2em 0;
}
.portfolio-container{
    margin: 5em 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;  
  }
  figure{
    width: 100%;
    height: 140px;
    overflow: hidden;
    margin: 0;
    border-radius: 5px;
  }
  
  .grid-container div {
    display:inline-block;
    width: 80%;
    max-height: 210px;
    border: solid 1px rgb(255, 255, 255);
    padding: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
    background-color: var(--neutral-background-color);
    margin: 0 0 0 9%;
  }
  
  .grid-container div:hover img {
    filter: grayscale(0);
  }
  
  .grid-container div:hover {
    border-color: var(--alternative-blue);
    max-height: 210px;
    transition: all 0.25s ease-in-out;
  }
  .grid-container div:hover .tags{
    opacity: 100%;
  }
  
  .grid-container div img {
    width: 100%;
    height: 150px;
    filter: grayscale(100%);
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
    object-fit: contain;
  }
  
  .grid-container div .names{
    margin: -25px 0 0 0;
    padding: 0;
    text-align: center;
    font-family: 'Raleway';
    font-weight: 300;
    transition: all 0.25s ease-in-out;
  }
  .block {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .block.visible {
    opacity: 1;
    transform: translateY(0);
  }
  /*TAGS*/
  .grid-container .portfolio-tags{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .grid-container div .tags{
  display: flex;
  opacity: 0;
  padding: 2px;
  margin: 10px 0 0 0;
  width: 20%;
  font-family: 'Raleway';
  font-weight: 300;
  background-color: var(--blue);
  justify-content: center;
  border-radius: 5px;
  color: var(--text-color);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border:  solid 1px var(--neutral-background-color);
  transition: all 0.25s ease-in-out;
  }
  .grid-container div:hover .names{
    margin: 10px 0 0 0;
    transition: all 0.25s ease-in-out;
    font-weight: 400;
  }
  /******************DESIGN PART*****************/
  .portfolio-container .second-title{
    position: absolute;
    display: flex;
    justify-content: center;
    top: 35em;
    left: 30%;
    right: 30%;
  }
  .design-container{
    width: 100%;
    position: absolute;
    top: 60em;
  }
  .design-container .Uiux-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 0 6em 0;
  }
  .design-container .Uiux-section .img-1-container,.img-2-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 500px;
    border: #fff 1px solid;
    overflow: hidden;
    border-radius: 25px;
    box-shadow: 0px 0px 10px rgb(255, 255, 255);
    margin: 0 0 1em 0;
    background-color: #ffffff32;
  }
  .design-container .Uiux-section .img-2-container{
    padding: 0 1em 0 1em;
  }
  .design-container .Uiux-section .img-2-container img{
  margin: 0 0 2em 0;
  border-radius: 25px;
  border: solid 1px var(--alternative-text-color);
  }
  .design-container .Uiux-section img{
    object-fit: contain;
    width: 100%;
    margin: 0 0 0 4em;
  }
  .design-container .Uiux-section h3{
    font-family: 'Raleway';
    font-weight: 400;
    padding: 10px;
    border: solid 1px var(--text-color);
    border-radius: 10px;
    background-color: #fff;
  }
  .design-container .design-tags{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .design-container .Uiux-section .tags{
  display: flex;
  padding: 2px;
  margin: 10px 0 0 0;
  width: 30%;
  font-family: 'Raleway';
  font-weight: 300;
  background-color: var(--alternative-blue);
  justify-content: center;
  border-radius: 5px;
  color: var(--text-color);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border:  solid 1px var(--neutral-background-color);
  }
  