:root{
    --blue: #94BCE9;
    --alternative-blue: #4273ac;
    --pink: #EEAECA ;
    --alternative-pink: #E080AA ;
    --text-color : #fff;
    --alternative-text-color: #000 ;
  }
/*************LINKS COLORS************/
a{
color: var(--alternative-text-color);
}
a:visited{
color: var(--alternative-text-color);
}
/*************************************/
.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    background: rgb(238,174,202);
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%); 
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.93);
}
/*________________NAV______________________*/
.header-container .nav-container{
    display: flex;
}
.header-container nav{
    display: flex;
    flex-direction: row;
}
.header-container nav .btn-container{
    display: flex;
    flex-direction: row;
    margin: 0 100px 0 0;
}
.header-container nav .icons{
    position: absolute;
    margin: 30px 0 0 25px;
    width: 1.3em;
    border-radius: 25px;
    padding: 10px 10px 10px 10px;
    border: solid #ffffff 1px;
    box-shadow: px 0px 6px rgb(255, 255, 255);
    transition-duration: 100ms;
    z-index: 2;
    font-size: 25px; 
    color: var(--alternative-text-color);
    background-color: #fff;
}
.header-container nav .icons:hover{
  transition: 100ms ease;
  background-color: #ffffff;
  color: var(--alternative-blue);
}
/*_________________LOGO___________________*/
.header-container .logo-container{
    width: 400px;
    height: 400px;
    position : absolute;
}
#logo1{
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-top: -10px;
}
