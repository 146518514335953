/*___________CARD STYLE____________*/
:root{
    --blue: #94BCE9;
    --alternative-blue: #4273ac;
    --pink: #EEAECA ;
    --alternative-pink: #E080AA ;
    --text-color : #fff;
    --alternative-text-color: #000 ;
  }
p{
  color: var(--text-color);
}
h1{
  color: var(--text-color);
}
h2{
  color: var(--blue);
}
.main-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90em;
    margin-top: -0em;
}
.main-container .first-section{
    display: flex;
    justify-content: center;
}
.main-container section:first-child{
    position: relative;
    height: 180px;
  }
.main-container .titles{
    position: absolute;
    top: 50%;
    margin-top:-35.5px;
    letter-spacing: 10px;
  }
.main-container .titles h1, .titles h2{
    font-family:'Merriweather', sans-serif; 
    font-size:28px; 
    font-weight:400; 
    text-transform: uppercase;
    text-align: center;
  }
.main-container .titles h2{
    font-size: 20px;
    font-weight:100;
    letter-spacing: 5px;
    margin-left: 13px;
  }
  .parallax-inner{
    position:relative;
    min-height: 400px;
    background-position: top center;
    background-attachment: fixed;
    background-size: cover;
  }
  .parallax-inner > h2{
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20.5px;
    width: 100%;
    text-align: center;
  }
.main-container .p-one{
    background-image:  linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('https://i.goopics.net/ury62a.jpg');
    margin-top: 2em;
}
   .content-text p{
    padding: 25px 50px 25px 50px;
    font-weight: 400;
    text-align: justify;
    line-height: 24px;
    font-family: 'Raleway';
    font-weight: 300;
  }
  .first-letter{
    float: left;
    font-weight: 400;
    font-size: 44px;
    line-height: 34px;
    padding-top: 2px;
    padding-right: 2px;
    padding-left: 4px;
  }
  .line-break{
    border-bottom: 1px solid #ffffff;
    width: 200px;
    margin: 0 auto;
  }
  .main-container .p-two{
    background-image:  linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('https://i.goopics.net/iw6saw.jpg');
    min-height: 200px;
}
.competences{
width: 100%;
display: flex;
margin: 2em 0 2em 0;
}
.competences .tags-container{
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.competences .tags-container li{
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 6px rgb(0, 0, 0);
  transform: scale(1);
  transition: ease 500ms;
}
.competences .tags-container li:hover{
  transform: scale(1.05);
  transition: ease 500ms;
  box-shadow: 1px 1px 6px rgb(255, 255, 255);
}




  /* media queries */
  @media (max-width: 768px) {
    .main-container{
        height: 20em;
        margin-top: 20em;
        position: absolute;
    }
    .main-container .titles{
        z-index: 4;
    }
    .main-container .titles h1{
        margin: 0 0 1em 0;
        font-size: 20px;
        color:var(--text-color);
    }
    .main-container .titles h2{
        font-size: 15px;
        padding: 1em;
        color: var(--text-color);
    }
    .main-container section:first-child{
        height: 400px;
        margin: 0 0 5em 0;
    }
    .main-container .section-1{
        margin: 0 0 2em 0;
    }
    .main-container .section-2{
        margin-top: 5em;
    }
    .parallax-inner{
        min-height: 250px;
        background-size: cover;
      }
    .main-container .p-two{
        min-height: 150px;
    }
    .competences{
      flex-wrap: wrap;
    }
    .competences .tags-container{
      flex-wrap: wrap;
      padding-left: 0;
    }
    .competences .tags-container li{
      margin: 1em 0 0 0;
    }
  }
  /*______________PORTFOLIO PART______________*/
  .portfolio-part{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 0 2em 0;
  }
  .portfolio-part h2{
    display: flex;
    font-family: 'Merriweather';
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-size: 15px;
    justify-content: center;
    margin: 2em;
  }
  /****************BUTTON******************/
  button {
    border: none;
    padding: 20px 40px;
    font-size: 30px;
    font-weight: 300;
    position: relative;
    background: transparent;
    color: #ffffff;
    text-transform: uppercase;
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: all 0.7s;
    overflow: hidden;
    border-radius: 100px;
    font-family: 'Raleway';
  }

  button:hover {
    color: #000;
  }
  span {
    transition: all 0.7s;
    z-index: -1;
  }

  button .first {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: 25%;
    height: 100%;
    background: var(--blue);
  }

  button:hover .first {
    top: 0;
    right: 0;
  }
  button .second {
    content: "";
    position: absolute;
    left: 25%;
    top: -100%;
    height: 100%;
    width: 25%;
    background: var(--blue);
  }

  button:hover .second {
    top: 0;
    left: 50%;
  }

  button .third {
    content: "";
    position: absolute;
    left: 50%;
    height: 100%;
    top: 100%;
    width: 25%;
    background: var(--blue);
  }

  button:hover .third {
    top: 0;
    left: 25%;
  }

  button .fourth {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 25%;
    background: var(--blue);
  }

  button:hover .fourth {
    top: 0;
    left: 0;
  }

